<template>
  <div v-if="device" class="device-container">
    <div class="device-header">
      <!-- Sección izquierda: Información principal (50%) -->
      <div class="header-left">
        <!-- Módulo 1: Nombre del vehículo con elipsis para nombres largos -->
        <div class="device-name">
          <span class="status-circle"
      :class="{
        'circle-online': device.status === 'online',
        'circle-offline': device.status === 'offline',
        'circle-unknown': device.lastUpdate === null || device.status !== 'online' && device.status !== 'offline'
      }"
      @mouseenter.stop="showTip($event, device.lastUpdate === null ? KT('device.unknown') : 
                        device.status === 'online' ? KT('device.online') : 
                        device.status === 'offline' ? KT('device.offline') : 
                        KT('device.unknown'))" 
      @mouseleave="hideTip">
</span>
          <div class="name-container" @mouseenter.stop="showTip($event, device.name)" @mouseleave="hideTip">
            {{ device.name }}
          </div>
        </div>
        
        <!-- Módulo 2: Placa / Información de identificación -->
        <div class="info-row">
          <div class="info-chip" v-if="device.attributes && device.attributes.placa" @mouseenter.stop="showTip($event, KT('device.plate'))" @mouseleave="hideTip">
            <i class="fas fa-id-card"></i> {{ device.attributes.placa }}
          </div>
          
          <div class="info-chip" v-if="device.uniqueId" @mouseenter.stop="showTip($event, KT('device.imei'))" @mouseleave="hideTip">
            <i class="fas fa-barcode"></i> {{ device.uniqueId }}
          </div>
          
          <div class="info-chip" v-if="position && position.protocol" @mouseenter.stop="showTip($event, KT('device.protocol'))" @mouseleave="hideTip">
            <i class="fas fa-qrcode"></i> {{ position.protocol }}
          </div>
        </div>
        
        <!-- Módulo 3: Dirección -->
        <div v-if="position" class="address">
          <i class="fas fa-map-marker-alt"></i>
          <span v-if="position.address">{{ position.address }}</span>
          <span v-else-if="position.latitude !== undefined && position.longitude !== undefined">{{ position.latitude }}, {{ position.longitude }}</span>
          <span v-else>{{ KT('device.noPosition') }}</span>
        </div>
        <div v-else class="address no-position">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ KT('device.noPosition') }}</span>
        </div>
        
        <!-- Módulo 4: Conductor (opcional) -->
        <div v-if="position && position.attributes && position.attributes.driverUniqueId" class="driver-info">
          <div :set="driver = store.getters['drivers/getDriverByUniqueId'](position.attributes.driverUniqueId)">
            <template v-if="driver">
              <i class="far fa-id-card"></i>
              <div class="driver-info-content">
                <span class="driver-name" @mouseenter.stop="showTip($event, driver.name)" @mouseleave="hideTip">
                  {{ driver.name }}
                </span>
                <img :src="`/tarkan/assets/images/drivers/${driver.id}.png`" 
                    :alt="driver.name" 
                    onerror="this.onerror=null;this.src='/tarkan/assets/images/drivers/default.png';" 
                    class="driver-photo" />
              </div>
            </template>
            <template v-else>
              <i class="far fa-id-card"></i>
              <span class="driver-id">{{ position.attributes.driverUniqueId }}</span>
            </template>
          </div>
        </div>
        
        <!-- Módulo 5: Última actualización -->
        <div v-if="position && position.fixTime" class="time-info">
          <div class="fixtime" @mouseenter.stop="showTip($event, KT('device.lastPositionTime'))" @mouseleave="hideTip">
            <i class="far fa-clock"></i>
            <span :class="{ 'text-danger': (new Date() - new Date(position.fixTime) > 1000 * 60 * 60 * 24) }">
              {{ position.fixTime ? new Date(position.fixTime).toLocaleString() : KT('device.new') }}
            </span>
            <span v-if="device.lastUpdate" class="last-update">
              {{ getLastUpdated(device.lastUpdate, now) }}
            </span>
          </div>
          
          <div v-if="position.deviceTime && position.fixTime && new Date(position.deviceTime) - new Date(position.fixTime) > 1000 * 60 * 60 * 3"
              class="device-time" @mouseenter.stop="showTip($event, KT('device.ultconection'))" @mouseleave="hideTip">
            <i class="far fa-clock"></i>
            <span>{{ new Date(position.deviceTime).toLocaleString() }}</span>
          </div>
        </div>
        
        <!-- Módulo 6: Espacio adicional / reserva -->
        <div class="extra-space"></div>
      </div>
      
      <!-- Sección derecha (50%) - Dividida en 3 módulos de igual altura -->
      <div class="header-right">
        <!-- Módulo 1: Distancia (ocupando espacio de un módulo) -->
        <div class="distance-module">
          <div class="distance-box">
            <i class="fas fa-road"></i>
            <div class="distance-digits">
              <template v-if="position && position.attributes && position.attributes.totalDistance !== undefined">
                <template v-for="(digit, index) in getFormattedDistance(position.attributes.totalDistance, 6).split('')" :key="index">
                  <div class="digit-box">{{ digit }}</div>
                </template>
                <span class="unit">{{ store.getters['server/getAttribute']('distanceUnit', 'distanceUnit') }}</span>
              </template>
              <template v-else>
                <template v-for="(digit, index) in '000000'" :key="index">
                  <div class="digit-box">{{ digit }}</div>
                </template>
                <span class="unit">{{ store.getters['server/getAttribute']('distanceUnit', 'distanceUnit') }}</span>
              </template>
            </div>
          </div>
        </div>
        
        <!-- Módulo 2: Imagen del vehículo (4 veces el tamaño del módulo 1) -->
        <div class="car-image-container">
          <Device-Image :id="device.id" :category="device.category" />
        </div>
        
        <!-- Módulo 3: Botones de acción -->
        <div class="action-buttons-container">
          <div class="action-buttons">
            <el-button v-if="store.state.server.isPlus && store.getters.advancedPermissions(9)" type="success"
                      @click="actAnchor(device.id)" @mouseleave="hideTip"
                      @mouseenter.stop="showTip($event, KT((!store.getters['geofences/isAnchored'](device.id)) ? 'actions.anchorEnable' : 'actions.anchorDisable'))"
                      :plain="!store.getters['geofences/isAnchored'](device.id)" :key="device.id"
                      class="action-btn">
              <i class="fas fa-anchor"></i>
            </el-button>

            <el-button v-if="position && position.attributes && (position.attributes.blocked && store.getters.advancedPermissions(11)) || store.getters['server/getAttribute']('tarkan.enableLockUnlock') && store.getters.advancedPermissions(11)"
                      type="success" @click="actUnlock(device.id)" :key="device.id" :disabled="device.status === 'offline'"
                      @contextmenu.prevent="actContext($event)" plain
                      class="action-btn" @mouseenter.stop="showTip($event, KT('actions.engineResume'))" @mouseleave="hideTip">
              <i class="fas fa-unlock"></i>
            </el-button>

            <el-button v-if="position && position.attributes && (!position.attributes.blocked && store.getters.advancedPermissions(10)) || store.getters['server/getAttribute']('tarkan.enableLockUnlock') && store.getters.advancedPermissions(10)"
                      type="warning" @click="actBlock(device.id)" @mouseleave="hideTip"
                      @mouseenter.stop="showTip($event, KT('actions.engineStop'))" :key="device.id"
                      :disabled="device.status === 'offline'" plain
                      class="action-btn">
              <i class="fas fa-lock"></i>
            </el-button>

            <el-button 
  v-if="store.getters.advancedPermissions(14)" 
  type="primary" 
  @click="openEdit(device.id)" 
  @mouseleave="hideTip" 
  @mouseenter.stop="showTip($event, KT('device.edit'))" 
  :key="device.id" 
  plain 
  class="action-btn">
  <i class="fas fa-edit"></i>
</el-button>


            <el-button type="danger" @click="markerClick(device.id)" @mouseleave="hideTip"
                      @mouseenter.stop="showTip($event, KT('device.details'))" plain
                      class="action-btn">
              <i class="fas fa-car"></i>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Separador -->
    <div class="separator"></div>
    
    <!-- Barra de estado/sensores con todos los iconos -->
    <div class="status-bar">
      <!-- Sección izquierda con sensores técnicos -->
      <div class="status-left">
        <!-- Alarma -->
        <div v-if="position && position.attributes && position.attributes.alarm" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.alarm'))" @mouseleave="hideTip">
          <i class="fas fa-exclamation-triangle"></i>
          <span>{{ position.attributes.alarm }}</span>
        </div>

        <!-- RSSI -->
        <div v-if="position && position.attributes && position.attributes.rssi" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.rssi'))" @mouseleave="hideTip">
          <i class="fas fa-signal"></i>
          <span>
            <template v-if="position.network && position.network.networkType">{{ position.network.networkType }} | </template>
            {{ position.attributes.rssi }}
          </span>
        </div>

        <!-- Operador -->
        <div v-if="position && position.attributes && position.attributes.operator" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.operator'))" @mouseleave="hideTip">
          <i class="fas fa-mobile"></i>
          <span>{{ position.attributes.operator }}</span>
        </div>
        <div v-else-if="device && device.attributes && device.attributes.operator" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.operator'))" @mouseleave="hideTip">
          <i class="fas fa-mobile"></i>
          <span>{{ device.attributes.operator }}</span>
        </div>
        
        <!-- Power -->
        <div v-if="position && position.attributes && position.attributes.power" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.power'))" @mouseleave="hideTip">
          <i class="fas fa-car-battery"></i>
          <span>{{ (parseFloat(position.attributes.power)).toFixed(2) }}v</span>
        </div>

        <!-- Satélites -->
        <div v-if="position && position.attributes && position.attributes.sat" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.sattelites'))" @mouseleave="hideTip">
          <i class="fas fa-satellite"></i>
          <span>{{ position.attributes.sat }}</span>
        </div>

        <!-- Batería -->
        <div v-if="position && position.attributes && position.attributes.battery" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.battery'))" @mouseleave="hideTip">
          <i class="fas fa-battery-full"></i>
          <span>{{ (parseFloat(position.attributes.battery)).toFixed(2) }}v</span>
        </div>

        <!-- Temperatura -->
        <div v-if="position && position.attributes && position.attributes.temperature" class="status-item"
             @mouseenter.stop="showTip($event, KT('device.temperature'))" @mouseleave="hideTip">
          <i class="fas fa-thermometer-full"></i>
          <span>{{ position.attributes.temperature }}ºc</span>
        </div>
      </div>
      
      <!-- Sección central para velocidad -->
      <div class="status-center">
        <!-- Velocidad en la sección central -->
        <div v-if="position && position.speed !== undefined" class="speed-display status-item">
          <i class="fas fa-tachometer-alt" :class="{
            'text-green': position.speed === 0,
            'text-blue': position.speed > 0
          }"></i>
          <div class="speed-value" :class="{
            'speed-warning': device.attributes && device.attributes.speedLimit && position.speed > device.attributes.speedLimit
          }">
            {{$t('units.' + store.getters['server/getAttribute']('speedUnit', 'speedUnit'), {speed: position.speed})}}
          </div>
        </div>
        <div v-else class="speed-display status-item">
          <i class="fas fa-tachometer-alt text-green"></i>
          <div class="speed-value">
            {{$t('units.' + store.getters['server/getAttribute']('speedUnit', 'speedUnit'), {speed: 0})}}
          </div>
        </div>
      </div>
      
      <!-- Sección derecha - Iconos de estado principales -->
      <div class="status-right">
        <!-- Estado de movimiento -->
        <div v-if="position && position.speed > 6 && device.status === 'online'" class="status-item text-blue important-status"
             @mouseenter.stop="showTip($event, KT('device.moving'))" @mouseleave="hideTip">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div v-else-if="position && position.speed === 0 && device.status === 'online'" class="status-item text-green important-status"
             @mouseenter.stop="showTip($event, KT('device.online'))" @mouseleave="hideTip">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div v-else-if="device.status === 'offline'" class="status-item text-red important-status"
             @mouseenter.stop="showTip($event, KT('device.stoped'))" @mouseleave="hideTip">
          <i class="fas fa-stop"></i>
        </div>
        <div v-else-if="device.status === 'unknown'" class="status-item text-orange important-status"
             @mouseenter.stop="showTip($event, KT('device.unknown'))" @mouseleave="hideTip">
          <i class="fas fa-question-circle"></i>
        </div>

        <!-- Ícono de anclaje (si está habilitado) -->
        <template v-if="store.state.server.isPlus && store.getters.advancedPermissions(9)">
          <div v-if="position && store.getters['geofences/isAnchored'](device.id)"
            @mouseenter.stop="showTip($event, KT('device.anchorEnabled'))" @mouseleave="hideTip"
            class="status-item important-status" style="color: var(--el-color-warning)">
            <i class="fas fa-anchor"></i>
          </div>
          <div v-else @mouseenter.stop="showTip($event, KT('device.anchorDisabled'))" @mouseleave="hideTip"
            class="status-item important-status" style="color: var(--el-color-success)">
            <i class="fas fa-anchor"></i>
          </div>
        </template>

        <!-- Ícono de bloqueo -->
        <div v-if="position && position.attributes && position.attributes.blocked === true"
          @mouseenter.stop="showTip($event, KT('device.blocked'))" @mouseleave="hideTip"
          class="status-item important-status" style="color: var(--el-color-danger)">
          <i class="fas fa-lock"></i>
        </div>
        <div v-else-if="position && position.attributes && position.attributes.blocked === false"
          @mouseenter.stop="showTip($event, KT('device.unblocked'))" @mouseleave="hideTip"
          class="status-item important-status" style="color: var(--el-color-success)">
          <i class="fas fa-lock-open"></i>
        </div>
        <div v-else @mouseenter.stop="showTip($event, KT('device.unknown'))" @mouseleave="hideTip"
          class="status-item important-status" style="color: var(--el-color-info)">
          <i class="fas fa-lock-open"></i>
        </div>
        
        <!-- Ícono de encendido (ignition) -->
        <div v-if="position && position.attributes && position.attributes.ignition === true"
          @mouseenter.stop="showTip($event, KT('device.ignitionOn'))" @mouseleave="hideTip"
          class="status-item important-status" style="color: var(--el-color-success)">
          <i class="fas fa-key"></i>
        </div>
        <div v-else-if="position && position.attributes && position.attributes.ignition === false"
          @mouseenter.stop="showTip($event, KT('device.ignitionOff'))" @mouseleave="hideTip"
          class="status-item important-status" style="color: var(--el-color-danger)">
          <i class="fas fa-key"></i>
        </div>
        <div v-else @mouseleave="hideTip" @mouseenter.stop="showTip($event, KT('device.unknown'))"
          class="status-item important-status" style="color: var(--el-color-info)">
          <i class="fas fa-key"></i>
        </div>
        
        <!-- Estado general / Conexión -->
        <div class="status-item important-status">
          <span v-if="device.lastUpdate === null" 
                @mouseenter.stop="showTip($event, KT('device.unknown'))" @mouseleave="hideTip">
            <i class="fas fa-question-circle text-info"></i>
          </span>
          <span v-else-if="device.status === 'online'" 
                @mouseenter.stop="showTip($event, KT('device.online'))" @mouseleave="hideTip">
            <i class="fas fa-check-circle text-success"></i>
          </span>
          <span v-else-if="device.status === 'offline'" 
                @mouseenter.stop="showTip($event, KT('device.offline'))" @mouseleave="hideTip">
            <i class="fas fa-exclamation-circle text-danger"></i>
          </span>
          <span v-else @mouseenter.stop="showTip($event, KT('device.unknown'))" @mouseleave="hideTip">
            <i class="fas fa-question-circle text-warning"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading-message">
    <div>{{ KT('device.loading') }}</div>
  </div>
</template>

<script setup>
import { computed, defineProps, inject, ref } from 'vue';
import { useStore } from "vuex";
import DeviceImage from "./device.image";
import { useRoute } from 'vue-router';
import { ElButton, ElMessageBox, ElNotification, ElMessage } from "element-plus";
import KT from '../tarkan/func/kt.js';
import { useRouter } from "vue-router";

// Optimiza la configuración de Font Awesome para mejor rendimiento
if (window.FontAwesomeConfig === undefined) {
  window.FontAwesomeConfig = {
    autoReplaceSvg: false,
    observeMutations: false,
    keepOriginalSource: false,
    searchPseudoElements: false,
    showMissingIcons: false,
    autoA11y: false
  };

  if (window.FontAwesome) {
    window.FontAwesome.config.autoReplaceSvg = false;
    window.FontAwesome.dom.i2svg = function() {};
  }
}

const router = useRouter();
const editDeviceRef = inject('edit-device');
const store = useStore();
const route = useRoute();
const now = ref(new Date().getTime());
const contextOpenRef = inject('contextMenu');
const actAnchor = inject('act-anchor');
const flyToDevice = inject('flyToDevice');

// Actualiza el tiempo cada 3 segundos para mejor rendimiento
setInterval(() => {
  now.value = new Date().getTime();
}, 3000);

const props = defineProps({
  device: {
    type: Object
  }
});

const device = computed(() => {
  return props.device;
});

const position = computed(() => {
  return store.getters['devices/getPosition'](props.device?.id);
});

// Contador de filtros desde el store (no usado actualmente)

// Formatea la distancia según la unidad seleccionada
const getFormattedDistance = (totalDistance, digits = 5) => {
  if (!totalDistance && totalDistance !== 0) return '0'.padStart(digits, '0');
  const unitType = store.getters['server/getAttribute']('distanceUnit', 'distanceUnit');
  let distance = 0;
  
  if (unitType === 'km') {
    distance = Math.floor(totalDistance / 1000);
  } else if (unitType === 'mi') {
    distance = Math.floor(totalDistance / 1609.34);
  } else {
    distance = Math.floor(totalDistance);
  }
  
  // Asegura que siempre tenga el número especificado de dígitos
  return distance.toString().padStart(digits, '0');
};

const showTip = (evt, text) => {
  window.$showTip(evt, text);
};

const hideTip = (evt) => {
  window.$hideTip(evt);
};

const openEdit = (id = false) => {
  editDeviceRef.value.editDevice(id ? id : device.value.Id);
};

const actBlock = async (deviceId) => {
  try {
    const device = store.getters['devices/getDevice'](deviceId);
    const response = await window.$traccar.getAvailableCommands(parseInt(deviceId));
    const availableSaved = response.data;

    ElMessageBox.confirm(
      KT('device.questio_bloked') + device.name + ' (IMEI ' + device.uniqueId + ')?',
      KT('device.question_yesblocked'),
      {
        confirmButtonText: KT('OK'),
        cancelButtonText: KT('Cancel'),
        type: 'warning',
      }
    ).then(() => {
      const changeNative = availableSaved.find((a) => a.attributes['tarkan.changeNative'] && a.attributes['tarkan.changeNative'] === 'engineStop');
      if (changeNative) {
        window.$traccar.sendCommand({ ...changeNative, ...{ deviceId: deviceId } });
      } else {
        window.$traccar.sendCommand({ deviceId: deviceId, type: "engineStop" });
      }

      ElNotification({
        title: KT('device.success'),
        message: KT('device.command_sent'),
        type: 'success',
      });
    }).catch(() => {
      ElMessage.error(KT('action_cancel'));
    });
  } catch (error) {
    ElMessage.error(KT('device.error'));
  }
};

const actUnlock = async (deviceId) => {
  try {
    const device = store.getters['devices/getDevice'](deviceId);
    const response = await window.$traccar.getAvailableCommands(parseInt(deviceId));
    const availableSaved = response.data;

    ElMessageBox.confirm(
      KT('device.question_blocked') + device.name + ' (IMEI ' + device.uniqueId + ')?',
      KT('device.question_noblocked'),
      {
        confirmButtonText: KT('OK'),
        cancelButtonText: KT('Cancel'),
        type: 'warning',
      }
    ).then(() => {
      const changeNative = availableSaved.find((a) => a.attributes['tarkan.changeNative'] && a.attributes['tarkan.changeNative'] === 'engineResume');
      if (changeNative) {
        window.$traccar.sendCommand({ ...changeNative, ...{ deviceId: deviceId } });
      } else {
        window.$traccar.sendCommand({ deviceId: deviceId, type: "engineResume" });
      }

      ElNotification({
        title: KT('device.success'),
        message: KT('device.command_sent'),
        type: 'success',
      });
    }).catch(() => {
      ElMessage.error(KT('action_cancel'));
    });
  } catch (error) {
    ElMessage.error(KT('device.error'));
  }
};

const actContext = (e) => {
  if (!store.getters.advancedPermissions(12)) {
    return false;
  }

  const deviceId = parseInt(route.params.deviceId);
  let commands = [];

  window.$traccar.getTypeCommands(deviceId).then((response) => {
    const availableTypesCommand = response.data;

    availableTypesCommand.forEach((c) => {
      commands.push({
        text: KT('actions.' + c.type), cb: () => {
          ElMessageBox.confirm(
            KT('device.confirm_command', device),
            'Warning',
            {
              confirmButtonText: KT('OK'),
              cancelButtonText: KT('Cancel'),
              type: 'warning',
            }
          ).then(() => {
            window.$traccar.sendCommand({ deviceId: deviceId, type: c.type });

            ElNotification({
              title: KT('success'),
              message: KT('device.command_sent'),
              type: 'success',
            });
          }).catch(() => {
            ElMessage.error(KT('userCancel'));
          });
        }
      });
    });

    window.$traccar.getAvailableCommands(deviceId).then((response) => {
      const availableSaved = response.data;

      if (commands.length > 0 && availableSaved.length > 0) {
        commands.push({ text: 'separator' });
      }

      availableSaved.forEach((c) => {
        commands.push({
          text: c.description, cb: () => {
            ElMessageBox.confirm(
              KT('device.confirm_command', device),
              'Warning',
              {
                confirmButtonText: KT('OK'),
                cancelButtonText: KT('Cancel'),
                type: 'warning',
              }
            ).then(() => {
              window.$traccar.sendCommand({ ...c, ...{ deviceId: deviceId } });

              ElNotification({
                title: KT('success'),
                message: KT('device.command_sent'),
                type: 'success',
              });
            }).catch(() => {
              ElMessage.error(KT('userCancel'));
            });
          }
        });
      });

      contextOpenRef.value.openMenu({ evt: e, menus: commands });
    });
  });
};

const getLastUpdated = (t, tt) => {
  if (t === null) {
    return KT('new');
  }

  tt = tt || new Date();
  const diffInSeconds = Math.round((new Date(tt).getTime() - new Date(t).getTime()) / 1000);

  if (diffInSeconds < 0) {
    return KT('now');
  } else if (diffInSeconds >= 86400) {
    return `${Math.floor(diffInSeconds / 86400)} ${KT('days')}`;
  } else if (diffInSeconds >= 3600) {
    return `${Math.floor(diffInSeconds / 3600)} ${KT('hours')}`;
  } else if (diffInSeconds >= 60) {
    return `${Math.floor(diffInSeconds / 60)} ${KT('minutes')}`;
  } else {
    return KT('lessMinute');
  }
};

const markerClick = (e) => {
  const deviceId = (e.target) ? e.target.options.id : e;
  router.push('/devices/' + deviceId);
  const deviceObj = store.getters['devices/getDevice'](deviceId);

  store.commit("devices/setFollow", deviceId);
  if (deviceObj.icon) {
    deviceObj.icon.bringToFront();
  }

  flyToDevice(deviceObj);
};
</script>

<style scoped>
/* Estilos del contenedor principal */
.device-container {
  border-radius: 8px;
  border: 1px solid #ebebeb;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  height: 140px;
  max-height: 140px;
  position: relative;
  will-change: transform;
}

.device-header {
  display: flex;
  padding: 8px;
  background-color: #f9f9f9;
  height: 100px;
  max-height: 100px;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
}

/* Columna izquierda - 50% del ancho, contiene 6 módulos verticales */
.header-left {
  flex: 0.65;
  min-width: 0;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Columna derecha - 50% del ancho, contiene 3 módulos de altura doble */
.header-right {
  flex: 0.35;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 2px;
  padding-top: 4px;
}

/* Módulo 1: Nombre del dispositivo */
.device-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.name-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1.5;
  min-width: 0;
  display: inline-block;
  max-width: calc(100% - 20px);
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 5px;
}

/* Colores para indicadores de estado */
.circle-moving {
  background-color: #409eff;
}

.circle-online {
  background-color: #67c23a;
}

.circle-offline {
  background-color: #f56c6c;
}

.circle-unknown {
  background-color: #e6a23c;
}

/* Módulo 2: Placa/Identificación */
.info-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 4px;
}

.info-chip {
  font-size: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Módulo 3: Dirección */
.address {
  display: flex;
  align-items: flex-start;
  font-size: 11px;
  color: var(--el-color-primary);
  margin: 2px 0;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address i {
  margin-right: 5px;
  margin-top: 2px;
  flex-shrink: 0;
}

.no-position {
  color: var(--el-text-color-disabled-base);
  opacity: 0.7;
}

/* Módulo 4: Conductor */
.driver-info {
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.driver-info > div {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.driver-info-content {
  display: flex;
  align-items: center;
  margin-left: 6px;
}

.driver-photo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 5px;
}

.driver-name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  max-width: 150px;
}

.driver-id {
  margin-left: 5px;
  color: #666;
  font-weight: bold;
}

/* Módulo 5: Última actualización */
.time-info {
  font-size: 10px;
  margin-top: 2px;
  max-height: 30px;
  overflow: visible;
}

.fixtime {
  display: flex;
  align-items: center;
}

.fixtime i {
  margin-right: 4px;
  flex-shrink: 0;
}

.last-update {
font-weight: bold;
  margin-left: 8px;
  color: #666;
}

.device-time {
  margin-top: 1px;
  color: #f56c6c;
}

/* Módulo 6: Espacio adicional */
.extra-space {
  height: 2px;
  flex-shrink: 1;
}

/* Módulo de distancia (columna derecha, primer módulo) */
.distance-module {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}

.distance-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.distance-digits {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.digit-box {
  background-color: #e0e0e0;
  color: black;
  padding: 1px 2px;
  font-size: 10px;
  font-weight: bold;
  margin-right: 1px;
  border-radius: 2px;
}

.unit {
  font-size: 9px;
  margin-left: 2px;
}

/* Imagen del vehículo (columna derecha, segundo módulo) */
.car-image-container {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 70px;
}

.car-image-container :deep(img) {
  max-width: 90px;
  max-height: 45px;
  object-fit: contain;
}

/* Botones de acción (columna derecha, tercer módulo) */
.action-buttons-container {
  height: 22px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 2px;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 2px;
  margin: 0;
  padding: 0;
}

.action-btn {
  width: 18px !important;
  height: 18px !important;
  padding: 0 !important;
  min-width: 18px !important;
  border-radius: 3px;
  margin-top: 0 !important;
}

.action-btn :deep(i) {
  font-size: 10px;
}

/* Separador entre cabecera y barra de estado */
.separator {
  height: 1px;
  background-color: #cccccc;
  margin: 0;
  width: 100%;
  display: block;
  z-index: 10;
}

/* Barra de estado/sensores (sección inferior) */
.status-bar {
  display: flex;
  padding: 2px 5px;
  background-color: #f9f9f9;
  justify-content: space-between;
  min-height: 28px;
  max-height: 28px;
  overflow: visible;
  margin: 0;
  position: relative;
  z-index: 2;
  bottom: 0;
}

/* Distribución de la barra de estado en tres zonas */
.status-left {
  display: flex;
  flex-wrap: wrap;
  flex: 0.4;
  justify-content: flex-start;
}

.status-center {
  display: flex;
  flex-wrap: nowrap;
  flex: 0.2;
  justify-content: center;
  align-items: center;
}

.status-right {
  display: flex;
  flex-wrap: nowrap;
  flex: 0.4;
  justify-content: space-between;
  margin-left: auto;
}

/* Elementos individuales de la barra de estado */
.status-item {
  font-size: 9px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0 4px;
  color: #666;
  height: 20px;
  padding: 0;
  position: relative;
  z-index: 3;
  line-height: 1;
  margin-top: 2px;
  overflow: visible;
}

.important-status {
  margin-left: 6px;
  font-size: 11px;
  line-height: 1;
  z-index: 4;
}

.status-item i {
  margin-right: 3px;
}

/* Estilos de velocidad en la barra de estado */
.status-center .speed-display {
  display: flex;
  align-items: center;
  justify-content: center;
}

.speed-value {
  background-color: #e0e0e0;
  color: black;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;
  margin-left: 3px;
  border-radius: 3px;
}

.speed-warning {
  background-color: #f56c6c;
  animation: blink 0.5s infinite;
}

/* Clases de color para estados */
.text-green {
  color: #67c23a;
}

.text-blue {
  color: #409eff;
}

.text-red {
  color: #f56c6c;
}

.text-orange {
  color: #e6a23c;
}

.text-danger {
  color: #f56c6c;
}

.text-success {
  color: var(--el-color-success);
}

.text-warning {
  color: var(--el-color-warning);
}

.text-info {
  color: var(--el-color-info);
}

/* Animaciones */
@keyframes blink {
  50% { opacity: 0.7; }
}

/* Mensaje de carga */
.loading-message {
  padding: 10px;
  text-align: center;
  color: #909399;
}

/* Estilos responsivos para móviles */
@media (max-width: 640px) {
  .device-container {
    height: 135px; /* Misma altura en móvil que en escritorio */
    max-height: 135px;
  }
  
  .device-header {
    flex-direction: row; /* Mantener en fila */
    height: 100px;
    max-height: 100px;
  }
  
  /* Mantener las mismas proporciones en móvil */
  .header-left {
    flex: 0.5;
    width: 50%;
  }
  
  .header-right {
    flex: 0.5;
    width: 50%;
  }
  
  /* Ajuste responsivo para que se vean todos los módulos */
  .device-name, .info-row, .address, .driver-info, .time-info {
    margin-bottom: 2px; /* Reducir espacios entre módulos */
  }
  
  .address {
    max-height: 2.4em; /* Limitar altura de la dirección */
  }
  
  /* Ajustes para que el diseño se mantenga consistente entre móvil y escritorio */
  .action-buttons {
    justify-content: flex-end;
  }
  
  .action-btn {
    margin: 1px;
    height: 18px !important;
    width: 18px !important;
    min-width: 18px !important;
  }
  
  /* Distribuir uniformemente la barra de estado */
  .status-bar {
    min-height: 28px;
    max-height: 28px;
  }
  
  .status-left,
  .status-center,
  .status-right {
    flex: 0.33;
    display: flex;
    overflow: visible;
  }
}
</style>